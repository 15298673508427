
import { Token } from "@pancakeswap/sdk";
import axios from "axios";
import BigNumber from "bignumber.js";
import erc721 from "config/abi/erc721Abi.json";
import multicall from "utils/multicall";
import { DataNftById, MetaData, PublicDataNft } from "./type";


export const fetchDataNft = async (nft:Token, chainId:number): Promise<{dataNft: PublicDataNft}> => {
    if ( nft ) {
        try {
            const calls = [
                {
                    address: nft?.address,
                    name: 'name',
                    params: []
                },
                {
                    address: nft?.address,
                    name: 'symbol',
                    params: []
                },
                {
                    address: nft?.address,
                    name: 'totalSupply',
                    params: []
                }
            ]
            const [ resName, resSymbol, resSupply ] = await multicall(erc721, calls, chainId)
            return {
                dataNft: {
                    name: resName[0],
                    symbol: resSymbol[0],
                    supply: new BigNumber(resSupply[0]?.toString())?.toString()
                }
            }
        } catch (e) {
            console.log(e)
            return { dataNft: null }
        } 
    }
    return { dataNft: null }
}

export const fetchDataNftById = async (nft:Token, nftId:string, chainId:number): Promise<{dataById: DataNftById}> => {
    if ( nftId !== "" || nftId !== null ) {
        try {
            const calls = [
                {
                    address: nft?.address,
                    name: 'ownerOf',
                    params: [nftId]
                },
                {
                    address: nft?.address,
                    name: 'tokenURI',
                    params: [nftId]
                },
            ]
            const [ resOwner, resTokenUrl ] = await multicall(erc721, calls, chainId)
            return {
                dataById: {
                    ownerOf: resOwner[0],
                    tokenUrl: resTokenUrl[0],
                }
            }
        } catch (e) {
            console.log(e)
            return { dataById: null }
        } 
    }
    return { dataById: null }
}

export const fetchDataByApi = async (nftId:string): Promise<{metaData: MetaData}> => {
    if(nftId?.length > 0) {
        try {
            const res = await axios.get(`/api/tokenid/${Number(nftId)}`)
            const { data } = res
            return {
                metaData: data
            }
        } catch (e) {
            console.log(e)
            return { metaData: null }
        }
    }
    return { metaData: null }
}

export const fetchSeri = async (seri:string): Promise<{dataSeri: MetaData}> => {
    if(seri?.length > 0) {
        try {
            const res = await axios.get(`/api/serinumber/${seri}`)
            const { data } = res
            return {
                dataSeri: data
            }
        } catch (e) {
            console.log(e)
            return { dataSeri: null }
        }
    }
    return { dataSeri: null }
}