import { Flex } from "@pancakeswap/uikit";
import styled from "styled-components";

export const ContainerImg = styled(Flex)`
    @media screen and (max-width:600px) {
        display: none;
    }
`

export const ContainerImgMobile = styled(Flex)`
    display: none;
    @media screen and (max-width:600px) {
        display: flex;
    }
`